import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic1 from '../assets/images/moca_ab_ramp.jpg'
import pic2 from '../assets/images/prosthesis_treadmill.gif'
import pic3 from '../assets/images/TreadmillSequence-01.jpg'
import gif1 from '../assets/images/Passive_prosthesis.gif'
import gif2 from '../assets/images/Active_prosthesis.gif'
import gif3 from '../assets/images/sheep_stroke.gif'
import gif4 from '../assets/images/sheep_healthy.gif'

import pic11 from '../assets/images/moca_ab_ramp.jpg'

import GoToTop from '../components/ui/GoToTop'


const Biomechanics = (props) => (
    <Layout>
        <Helmet>
            <title>Biomechanics - Jonathan Camargo</title>
            <meta name="description" content="Biomechanics" />
        </Helmet>

        <div id="main" className="alt">
            
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Biomechanics</h1>
                    </header>
                    <p>Understanding human motion requires observation of the kinematics and external forces, all associated with a system with hundreds of degrees of freedom. In my research, I use motion capture techniques to measure the position of critical locations in the body and fit them into accurate body models, identifying the joint locations and rotations that define the body posture.</p>                   
                    <span className="image main"><img src={pic1} alt="" /></span>                   
                </div>
            </section>

            <section id="two">
                <div className="inner">                    
                   <p>Marker-based motion capture requires a lot of work in postprocessing before using in biomechanics analysis. I proposed and studied an automated method of gap-filling that uses inverse kinematics (IK) to close the loop of an iterative process to minimize error while nearly eliminating user input.
                         Comparing our method to manual gap-filling, we observed a significant reduction in gap-filling error and completion time. 
                         Our contribution encompassed the release of an open-source repository of the method and interaction with OpenSim. This research is published in <a href="https://www.tandfonline.com/doi/full/10.1080/10255842.2020.1789971">Computer Methods in Biomechanics and Biomedical Engineering</a> and
                         the code can be accessed in the MoCapTools repository on Github. <a href="https://github.com/jonathancamargo/mocaptools">https://github.com/jonathancamargo/mocaptools</a>
                         </p>                                      
                </div>
            </section>          

            <section id="two">
                <div className="inner">                    
                    <p> Even for simple analyses, accurate models are a must for reliable results; besides, they offer better visualization of the movement. I have built models of subjects with amputation, taking into account the artificial limbs' geometry and inertial properties. It does not hurt to add nice colors too!
                    </p>
                    <span className="image main"><img src={pic2} alt="" /></span>                 
                </div>
            </section>

            <section id="two">
                <div className="inner">                    
                    <p> With data and models, we can identify locomotion patterns and the influence of different motor tasks on the biological control at the joint level. Further, with the observation of the electromyography signals, we can understand the controls from a neurological perspective and enable pattern recognition and seamless user interfaces. I have run a comprehensive experiment that includes walking in different scenarios of ramps, stairs, and levelground. This study and data is available in my paper in the <a href="https://www.sciencedirect.com/science/article/abs/pii/S0021929021001007">Journal of Biomechanics</a>.
                    Access to this dataset is also open in the <a href="http://www.epic.gatech.edu/opensource-biomechanics-camargo-et-al/"> EPIC lab website </a>.
                    </p>
                    <span className="image main"><img src={pic3} alt="" /></span>                 
                </div>
            </section>

            <section id="two">
                <div className="inner">                    
                    <p> I have extended this analysis to individuals with amputation, proposing improved strategies for prosthetic control. 
                    Under review in the <a href="http://www.ieee-tmrb.org/">Transactions on Medical Robotics and Bionics</a>, my latest submission focuses on comparing the stair ambulation with passive prostheses to our first version of the robotic leg. It contrasts the results to the Biomechanics of healthy subjects, proposing improvements to the controller that drives the leg towards the biological gait.                    
                    </p>
                    <div class="image main, grid-flow-row flex-container" style={{ display: 'flex', flexWrap:'wrap'}}>
                        <img src={gif1} width='40%' height='auto' style={{alignSelf: 'flex-start', display: 'flex', flex:'1', paddingRight:'1%'}} alt="" />
                        <img src={gif2} width='40%' height='auto' style={{alignSelf: 'flex-start', display: 'flex', flex:'1', }} alt="" />                            
                    </div>
                </div>
            </section>

            <section id="two">
                <div className="inner">                    
                    <p> With my Biomechanics expertise, I also had the opportunity to collaborate in the analysis of the quadrupedal gait. Using <a href="https://github.com/jonathancamargo/mocaptools">MoCapTools</a>, I have helped Neuroscientists 
                        from <a href="https://www.adelaide.edu.au">The University of Adelaide</a> to evaluate ovine locomotion related to central nervous system injury. Biomechanics research is exciting and can also extend to other applications like sports medicine and rehabilitation assessment. In the future, I would like to expand on interdisciplinary collaborations in Biology and Neuroscience, where understanding locomotion patterns will lead to novel sources of inspiration for robotics design.
                    </p>                                        
                    <div class="image main, grid-flow-row flex-container" style={{ display: 'flex', flexWrap:'wrap'}}>
                        <img src={gif3} style={{alignSelf: 'flex-start', display: 'flex', flex:'1', paddingRight:'1%', width: '40%'}} alt="" />
                        <img src={gif4} style={{alignSelf: 'flex-start', display: 'flex', flex:'1', width: '40%'}} alt="" />                            
                    </div>         
                </div>
            </section>

            <GoToTop></GoToTop>

        </div>

    </Layout>
)

export default Biomechanics